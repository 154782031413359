export const environment = {
    production: true,
    dealcoreBaseUrl: 'https://dealcore.hyundai-partners.de',
    autocoreBaseUrl: 'https://autocore.hyundai-partners.de',
    cloudinaryBaseUrl: 'https://res.cloudinary.com/hmd-pw-dfnosvs4u',
    showroomScriptUrl: 'https://showroom-scripts.hyundai.de/integration/integration.js',
    e2e: false,
    trackingTerm: 'Production',
    redirect: true,
    logs: false,
    vehicleMarketBaseUrl: 'https://www.hyundai-car-market.de',
    partnerwebBaseUrl: 'https://www.hyundai-partners.de',
};
